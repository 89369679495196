import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { selectBrand, selectMenu, useAppSelector } from '@open-tender/cloud'
import {
  fetchCustomerOrders,
  selectCustomer,
  selectCustomerOrders,
  selectMenuSlug,
  useAppDispatch,
} from '@open-tender/cloud'
import {
  makeMenuItemLookup,
  makeRecents,
  makeUniqueDisplayItems,
} from '@open-tender/utils'
import { Content, Loading, Main } from 'components'
import {
  MenuCategoryHeader,
  MenuHeader,
  MenuItems,
  MenuItem,
  MenuCategoryView,
} from '../Menu'

const MenuRecents = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { categories, soldOut } = useAppSelector(selectMenu)
  const menuSlug = useAppSelector(selectMenuSlug)
  const { auth } = useAppSelector(selectCustomer)
  const hasCustomer = auth ? true : false
  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])
  const orders = useAppSelector(selectCustomerOrders)
  const displayItems = useMemo(
    () => makeUniqueDisplayItems(orders.entities),
    [orders.entities]
  )
  const recents = useMemo(
    () => makeRecents(displayItems, itemLookup, soldOut),
    [displayItems, itemLookup, soldOut]
  )
  const hasRecents = recents && recents.length > 0
  const showLoading = orders.loading === 'pending' && !hasRecents ? true : false

  const shouldRedirect =
    !hasCustomer || (!hasRecents && orders.loading !== 'pending')

  useEffect(() => {
    if (hasCustomer) {
      dispatch(fetchCustomerOrders(11))
    }
  }, [dispatch, hasCustomer])

  useEffect(() => {
    if (shouldRedirect) navigate(menuSlug)
  }, [navigate, shouldRedirect, menuSlug])

  if (showLoading) return null

  return (
    <>
      <Helmet>
        <title>Recents | {siteTitle}</title>
      </Helmet>
      <Content scrollTop={false}>
        <MenuHeader />
        <Main>
          <MenuCategoryView>
            <MenuCategoryHeader
              title="Recents"
              subtitle="All of the items you've ordered across your last 10 orders"
            />
            {showLoading ? (
              <Loading />
            ) : (
              <MenuItems>
                {recents.map((item, index) => (
                  <MenuItem
                    key={`${item.id}-${index}`}
                    item={item}
                    favorite={item.favorite}
                  />
                ))}
              </MenuItems>
            )}
          </MenuCategoryView>
        </Main>
      </Content>
    </>
  )
}

export default MenuRecents
