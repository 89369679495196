import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import {
  selectBrand,
  selectCurrentItem,
  selectDisplaySettings,
  selectMenuPath,
  setCurrentItem,
  selectMenuSlug,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Content, Main, MenuItem, ScreenreaderTitle } from 'components'
import ItemHeader from './ItemHeader'

const Item = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { title: siteTitle, openGraphImage } = useAppSelector(selectBrand) || {}
  const menuPath = useAppSelector(selectMenuPath)
  const menuSlug = useAppSelector(selectMenuSlug)
  const item = useAppSelector(selectCurrentItem)
  const { builderType = 'PAGE' } = useAppSelector(selectDisplaySettings) || {}
  const showFullPage = isMobile ? false : builderType === 'PAGE_FULL'
  const [imageUrl, setImageUrl] = useState(item ? item.imageUrl : null)
  const fallbackImage = (imageUrl || item?.imageUrl) ?? null
  const style = showFullPage
    ? {}
    : isMobile
    ? { height: '100%', paddingTop: 0 }
    : { height: '100%' }

  const cancel = () => {
    dispatch(setCurrentItem(null))
  }

  useEffect(() => {
    if (!item) navigate(menuPath || menuSlug)
  }, [item, navigate, menuSlug, menuPath])

  useEffect(() => {
    if (item?.imageUrl) {
      setImageUrl(item.imageUrl)
    }
  }, [item?.imageUrl, openGraphImage])

  if (!item) return null

  return (
    <>
      <Helmet>
        <title>
          Menu - {item.name} | {siteTitle}
        </title>
      </Helmet>
      <Content hasFooter={showFullPage}>
        {!isMobile ? <ItemHeader /> : <></>}
        <Main style={style}>
          <ScreenreaderTitle>{item.name}</ScreenreaderTitle>
          <MenuItem
            cancel={cancel}
            showBack={isMobile}
            showClose={false}
            showImage={isMobile}
            showFullPage={showFullPage}
            imageUrlWithFallback={!showFullPage ? fallbackImage : undefined}
            setImageUrl={setImageUrl}
          />
          {/* {showFullPage ? (
            <MenuItem
              cancel={cancel}
              showBack={isMobile}
              showClose={false}
              showImage={isMobile}
              showFullPage={true}
              setImageUrl={setImageUrl}
            />
          ) : (
            <ItemPageView>
              <ItemPageImage>
                {showPlaceholder ? (
                  <ItemPageImagePlaceholder>
                    <img src={openGraphImage || undefined} alt={item.name} />
                  </ItemPageImagePlaceholder>
                ) : (
                  <BackgroundImage
                    imageUrl={imageUrlWithFallback}
                    bgSize={imageBgSize}
                  />
                )}
              </ItemPageImage>
              <ItemPageContent>
                <MenuItem
                  cancel={cancel}
                  showBack={isMobile}
                  showClose={false}
                  showImage={isMobile}
                  imageUrlWithFallback={imageUrlWithFallback}
                  setImageUrl={setImageUrl}
                />
              </ItemPageContent>
            </ItemPageView>
          )} */}
        </Main>
      </Content>
    </>
  )
}

export default Item
