import styled from '@emotion/styled'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { Button } from 'components'

const DeleteAccountView = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }

  button {
    color: ${(props) => props.theme.colors.light};
    background-color: ${(props) => props.theme.colors.error};
    border-color: ${(props) => props.theme.colors.error};

    &:hover,
    &:active {
      color: ${(props) => props.theme.colors.light};
      background-color: ${(props) => props.theme.colors.dark};
      border-color: ${(props) => props.theme.colors.dark};
    }
  }
`

const DeleteAccount = () => {
  const dispatch = useAppDispatch()

  const openDelete = () => {
    dispatch(openModal({ type: 'deleteAccount' }))
  }

  return (
    <DeleteAccountView>
      <Button onClick={openDelete}>Delete Your Account</Button>
    </DeleteAccountView>
  )
}

export default DeleteAccount
