import { useCallback, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { CreditCardData, CreditCardValidate } from '@open-tender/types'
import { useCreditCardForm } from '@open-tender/utils'
import {
  addCustomerCreditCard,
  resetCustomerCreditCardsError,
  selectCustomerCreditCards,
  selectRecaptcha,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  CreditCard,
  FormError,
  FormRecaptcha,
  FormSubmit,
} from 'components'

const recaptchaKeyEnvVar = process.env.REACT_APP_RECAPTCHA_KEY

const makeErrMsg = (formError: string | undefined) => {
  if (!formError || formError === 'Missing Customer') return null
  return formError.includes('parameters')
    ? 'There are one or more errors below'
    : formError
}

const CreditCardForm = ({
  windowRef,
  callback,
  submitText = 'Add New Card',
  submittingText = 'Authorizing Card...',
  revenue_center_id = null,
}: {
  windowRef: React.RefObject<HTMLDivElement>
  callback: () => void
  submitText?: string
  submittingText?: string
  revenue_center_id: number | null
}) => {
  const dispatch = useAppDispatch()
  const { addCard: includeRecaptcha } = useAppSelector(selectRecaptcha) || {
    addCard: false,
  }
  const { loading, error } = useAppSelector(selectCustomerCreditCards)
  const recaptchaKey =
    includeRecaptcha && recaptchaKeyEnvVar ? recaptchaKeyEnvVar : null
  const addCard = useCallback(
    (data: CreditCardData, callback: () => void) =>
      dispatch(addCustomerCreditCard({ data, callback })),
    [dispatch]
  )
  const [newCard, setNewCard] = useState<
    (CreditCardValidate & { isComplete: boolean }) | null
  >(null)
  const {
    card: cardData = null,
    cardType = null,
    // isComplete = false,
    // errors: cardErrors,
  } = newCard || {}
  const { submitRef, recaptchaRef, errors, submitting, handleSubmit } =
    useCreditCardForm(
      loading,
      error,
      cardData,
      cardType,
      addCard,
      callback,
      recaptchaKey,
      revenue_center_id
    )
  const errMsg = makeErrMsg(errors.form)

  useEffect(() => {
    dispatch(resetCustomerCreditCardsError())
    return () => {
      dispatch(resetCustomerCreditCardsError())
    }
  }, [dispatch])

  useEffect(() => {
    if (error && windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  return (
    <form id="credit-card-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      <CreditCard setCard={setNewCard} formErrors={errors} />
      {recaptchaKey && (
        <FormRecaptcha>
          <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
        </FormRecaptcha>
      )}
      <FormSubmit>
        <ButtonSubmit
          submitRef={submitRef}
          submitting={submitting}
          // disabled={!isComplete}
        >
          {submitting ? submittingText : submitText}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default CreditCardForm
