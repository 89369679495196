import styled from '@emotion/styled'
import { LoyaltyPerk, LoyaltyProgram } from '@open-tender/types'
import { formatDollars, formatQuantity } from '@open-tender/utils'
import {
  LoyaltyHeader,
  LoyaltyRewards,
  LoyaltyStatus,
  LoyaltyThresholds,
  ProgressPoints,
  SeeMoreLink,
} from '.'

const LoyaltyPointsView = styled.div`
  label: LoyaltyPointsView;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 2rem 0;
  }
`

const LoyaltyPoints = ({
  program,
  perk,
  showThresholds = false,
  to,
}: {
  program: LoyaltyProgram
  perk?: LoyaltyPerk | null
  showThresholds?: boolean
  to?: string
}) => {
  const { name, description, spend_name } = program
  const {
    credit,
    loyalty_perk,
    progress,
    threshold = null,
    thresholds,
    rewards,
  } = perk || {}
  const isBankablePoints = loyalty_perk === 'BANKABLE_POINTS'
  const isDollarsCredit = loyalty_perk === 'DOLLARS_CREDIT'
  const points = isBankablePoints
    ? parseInt(credit || '0')
    : parseInt(progress || '0')

  return (
    <LoyaltyPointsView>
      <LoyaltyHeader title={name} subtitle={description} />
      {isBankablePoints ? (
        <LoyaltyStatus count={formatQuantity(`${points}`)} name={spend_name}>
          {to ? <SeeMoreLink text="See Details" to={to} /> : undefined}
        </LoyaltyStatus>
      ) : isDollarsCredit && credit ? (
        <LoyaltyStatus
          count={formatDollars(credit, '', 2).replace('.00', '')}
          name="Available Credit"
        >
          {to && <SeeMoreLink text="See Details" to={to} />}
        </LoyaltyStatus>
      ) : null}
      {threshold || thresholds ? (
        <ProgressPoints
          name={spend_name}
          points={points}
          threshold={threshold}
          thresholds={thresholds}
        />
      ) : null}
      {thresholds && showThresholds && (
        <LoyaltyThresholds name={spend_name} thresholds={thresholds} />
      )}
      {rewards && <LoyaltyRewards rewards={rewards} />}
    </LoyaltyPointsView>
  )
}

export default LoyaltyPoints
