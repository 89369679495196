import styled from '@emotion/styled'
import { checkAmountRemaining, formatDollars } from '@open-tender/utils'
import {
  selectCheckout,
  selectOrder,
  setSubmitting,
  submitOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, ButtonLink, Message } from 'components'
import CheckoutSection from './CheckoutSection'

const CheckoutSubmitButton = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: center;
  }
`

const CheckoutSubmitMessage = styled.div`
  margin: 3rem 0 0;

  > div {
    margin: 0 0 3rem;
  }
`

const CheckoutSubmitSave = styled.div`
  margin: 3rem 0 0;
`

const CheckoutSubmit = () => {
  const dispatch = useAppDispatch()
  const { orderType } = useAppSelector(selectOrder)
  const { check, form, submitting, loading } = useAppSelector(selectCheckout)
  const updating = submitting ? false : loading === 'pending'
  const total = check?.totals.total
  const deposit = check?.deposit
  const amountDue = deposit || total
  const amountRemaining = amountDue
    ? checkAmountRemaining(amountDue, form.tenders)
    : 0.0
  const isPaid = Math.abs(amountRemaining).toFixed(2) === '0.00'
  const submitDisabled = submitting || !isPaid || updating
  const hasTotal = amountDue && parseFloat(amountDue) >= 0 ? true : false
  const totalAmount = hasTotal ? ` ${formatDollars(amountDue ?? '0')}` : ''

  const submitPayment = () => {
    dispatch(setSubmitting(true))
    dispatch(submitOrder())
  }

  const saveForLater = () => {
    dispatch(setSubmitting(true))
    dispatch(submitOrder({ save: true }))
  }

  return (
    <CheckoutSection>
      <CheckoutSubmitMessage>
        {!isPaid ? (
          <Message
            as="div"
            size="small"
            color="alert"
            style={{ width: '100%', padding: '1rem 1.5rem' }}
          >
            There is a balance of ${amountRemaining.toFixed(2)} remaining on
            your order. Please add a payment above.
          </Message>
        ) : null}
      </CheckoutSubmitMessage>
      <CheckoutSubmitButton>
        <Button
          onClick={submitPayment}
          disabled={submitDisabled}
          size="big"
          color="primary"
        >
          {updating ? 'Updating...' : `Submit Order${totalAmount}`}
        </Button>
      </CheckoutSubmitButton>
      {orderType === 'CATERING' ? (
        <CheckoutSubmitSave>
          <ButtonLink onClick={saveForLater}>
            Or save this order for later (your payment method will not be
            charged).
          </ButtonLink>
        </CheckoutSubmitSave>
      ) : null}
    </CheckoutSection>
  )
}

export default CheckoutSubmit
