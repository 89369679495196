import { useNavigate } from 'react-router-dom'
import {
  selectCustomer,
  selectCustomerTpls,
  useAppSelector,
} from '@open-tender/cloud'
import {
  AccountRewardsCardsContainer,
  AccountRewardsNavigationIcon,
  AccountWelcomeBackBox,
  AccountWelcomeBackGreeting,
  AccountWelcomeBackNameText,
  AccountWelcomeBackText,
  AccountWelcomeButton,
  AccountWelcomeButtonNavigationIcon,
  AccountWelcomeView,
} from './AccountWelcome.styled'
import NavigationIcon from 'components/icons/Navigation'
import { Body, Button, Heading, Welcome } from 'components'

const AccountWelcome = ({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}) => {
  const navigate = useNavigate()
  const { profile } = useAppSelector(selectCustomer)
  const { tpls } = useAppSelector(selectCustomerTpls)
  const firstName = profile ? profile.first_name : null
  const lastName = profile ? profile.last_name : null

  return (
    <AccountWelcomeView>
      <Welcome title={title} />
      <AccountWelcomeBackGreeting>
        {tpls ? (
          <AccountWelcomeBackBox>
            <AccountWelcomeBackText>
              {subtitle}{' '}
              <AccountWelcomeBackNameText>
                {firstName}!
              </AccountWelcomeBackNameText>
            </AccountWelcomeBackText>
            <AccountRewardsCardsContainer>
              <Button onClick={() => navigate('/points-shop')}>
                <AccountRewardsNavigationIcon>
                  <NavigationIcon />
                </AccountRewardsNavigationIcon>
                <Heading size="big">{tpls.points?.balance}</Heading>
                <Body size="main">My Points</Body>
              </Button>
              <Button onClick={() => navigate('/rewards')} color="secondary">
                <AccountRewardsNavigationIcon>
                  <NavigationIcon />
                </AccountRewardsNavigationIcon>
                <Heading size="big">{tpls.rewards.length}</Heading>
                <Body size="main">My Rewards</Body>
              </Button>
            </AccountRewardsCardsContainer>
          </AccountWelcomeBackBox>
        ) : (
          <AccountWelcomeBackBox>
            <AccountWelcomeBackText>
              {subtitle}{' '}
              <AccountWelcomeBackNameText>
                {firstName} {lastName}
              </AccountWelcomeBackNameText>
            </AccountWelcomeBackText>
            <AccountRewardsCardsContainer>
              <Button onClick={() => navigate('/signup')} color="secondary">
                <AccountRewardsNavigationIcon>
                  <NavigationIcon />
                </AccountRewardsNavigationIcon>
                <Heading size="big">Sign Up For An Account</Heading>
              </Button>
            </AccountRewardsCardsContainer>
          </AccountWelcomeBackBox>
        )}
        <AccountWelcomeButton>
          <Button onClick={() => navigate('/order-type')}>
            <AccountWelcomeButtonNavigationIcon />
            Order Now
          </Button>
        </AccountWelcomeButton>
      </AccountWelcomeBackGreeting>
    </AccountWelcomeView>
  )
}

export default AccountWelcome
